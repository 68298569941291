import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "id": "FAQ Items",
  "faqitems": [{
    "body": "Spoor allows you to track cargo, special cargo as well as equipment in transit. It is designed to be simple, fast and beautiful.\n\nBeing able to digitally analyse the flow of cargo and knowing where your equipment really is at any given time, allows you to identify operational inefficiencies. At the same time, you increase worker productivity: your employees don’t have to keep on requesting and sending out the same status messages over and over.\n\nAnd most importantly, you are also adding highly sought-after visibility for your customers – who can now get the information they seek at any time and at any place.",
    "identification": "Faq",
    "title": "In a nutshell, what is Spoor?"
  }, {
    "body": "__Spoor__\n_Track cargo from any origin to any destination._\nDesigned for tracking cargo on land and sea. Follow the cargo from port of loading to place of delivery, get information on ETAs, monitor conditions, create automated status reports and alerts and keep records of deliveries and trips.\n\nLearn more about the specific configuration in the answer to the Question: “What are the different configurations for Spoor?”\n\n__Spoor<sup>SC</sup> __\n_Track special cargo from any origin to the same destination._\nDesigned for tracking and monitoring special cargo on land and sea. Follow the cargo from loading port through inland terminal to place of delivery, know who delivers what, monitor conditions (speed, shock, etc.) and keep records of deliveries, trips and drivers.\n\nLearn more about the specific configuration in the answer to the Question: “What are the different configurations for Spoor<sup>SC</sup>?”\n\n__Spoor<sup>EQ</sup>__\n_Track equipment wherever it is._\nDesigned for tracking passive and active equipment. Know where your equipment is, whether it is loaded or discharged, where it was in the past and where it needs to be in the future.\n\nLearn more about the specific configuration in the answer to the Question: “What are the different configurations for Spoor<sup>EQ</sup>?”",
    "identification": "Faq",
    "title": "What are the differences between the three products?"
  }, {
    "body": "If you want to have a simple, quick and beautiful way of being able to track special cargo and show the information to your customers, then our Spoor<sup>SC</sup> __Basic__ configuration is for you.\n\nThis will give you access to our Spoor-branded web platform, a user, driver, truck & trailer management tool, an Excel data upload functionality for cargo details as well as trip history. You have the choice of buying or leasing our already supported GPS trackers and you will get periodic updates.\n\n__Standard__ configuration will let you customize Spoor with your  logo and you can use any GPS trackers. You will also have access to our API as well as a geofence management tool, which allows you to automate delivery records. Furthermore, you will have access to sea freight tracking from POL to POD and will receive regular updates.\n\nOur __Full__ configuration will give you native Android & iOS Apps, let you integrate Spoor with your ERP or TMS system and provide you with the ability to upload shipping documents to the system. You can create custom alerts and monitor conditions of the transport (such as speed, shock, etc.). You have the choice to save shipping status information on the Blockchain and we will customize or create further functionalities as per your needs.\n\nTo learn more about what is best for you, [get in touch with us](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Start&body=I'm%20interested%20in%20getting%20more%20information%20on%20the%20different%20configurations%20of%20SpoorSC.)!",
    "identification": "Faq",
    "title": "What are the different configurations for Spoor<sup>SC</sup>"
  }, {
    "body": "If you want to have a simple, quick and beautiful way of being able to track equipment, then our Spoor<sup>EQ</sup> __Basic__ configuration is what you are looking for.\n\nThis will give you access to our Spoor-branded web platform to track your equipment, the possibility of setting up custom geofences to display custom position events, periodic updates and you can choose to buy or lease our already supported GPS trackers.\n\nOur __Standard__ configuration lets you customize Spoor with your logo, use any GPS trackers; have a user access management tool and you will get regular updates. \n\nWith our __Full__ configuration you will get native Android & iOS Apps, we will integrate Spoor with your ERP or TMS system, allowing you to get loading status and post positions of every piece of equipment. You will have access to position history, get allocation predictions to ensure you won’t face any equipment bottlenecks and we will customize or create further functionalities as per your needs. \n\nTo learn more about what is best for you, [get in touch with us](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Start&body=I'm%20interested%20in%20getting%20more%20information%20on%20the%20different%20configurations%20of%20SpoorEQ.)!",
    "identification": "Faq",
    "title": "What are the different configurations for Spoor<sup>EQ</sup>?"
  }, {
    "body": "When choosing our full configurations for Spoor and Spoor<sup>SC</sup>, you have the choice to save the various shipping events in a permissioned blockchain. Currently, we are running on Hyperledger Fabric. This is an open standard started by the Linux Foundation and which has received contributions from IBM, Intel and SAP Ariba. Spoor is however blockchain agnostic, so we can integrate with the technology of your choice. \n\nStoring your shipping events on a distributed ledger (Blockchain) will add transparency for you and your stakeholders, since those events can’t be changed once written onto the chain. Especially when combined with IoT device generated real-world data, such as the arrival of the tracked cargo in a geofence, this can be very useful in building trust among the stakeholders and for resolving potential disputes.\n\nSince the blockchain technology we currently use is permissioned, only people you explicitly grant access will be able to see this information.\n\nTo learn more, [get in touch with us](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Start&body=I'm%20interested%20in%20getting%20more%20information%20on%20how%20blockchain%20can%20help%20me.)!",
    "identification": "Faq",
    "title": "What does “storing on the Blockchain” mean exactly?"
  }, {
    "body": "This depends on whether you purchase or lease GPS trackers. Leasing will have the lowest entry cost and you can choose to pay monthly or yearly (with a discount). \nHowever, purchasing trackers will be more cost efficient in the long run.\n\nOur pricing is based on monthly “active devices”. Only devices used will be billed for every active day per month. A device is active when it is paired with cargo and it has left a defined perimeter (yard, port, etc.), so you can pair in advance or have assets stored in designated spaces without incurring any costs.\n\n[Get in touch](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Start&body=I'm%20interested%20in%20getting%20more%20information%20on%20the%20pricing%20of%20Spoor.) to learn more about the prices we can offer to you.",
    "identification": "Faq",
    "title": "How do you calculate my monthly costs / What are active devices?"
  }, {
    "body": "There are two ways to access Spoor, depending on the configuration you choose.\nIn the __Basic__ configuration, you can track your consignments with a tracking ID, like modern courier companies. Everyone in possession of that ID can track it – no login required.\nIn the __Standard__ and the __Full__ configuration, additionally to the tracking ID method, you will also get a complete user management module. We authenticate the users with the secret token method (Auth0), which is not only super secure but also lets the user authenticate themself  with social media networks like Facebook, Google, Twitter, etc.\n\nFor Spoor<sup>SC</sup> you have the user management already in the Basic version.\n\nLearn more about the specific configuration in the answer to the Question: \n> What are the differences between the three products?",
    "identification": "Faq",
    "title": "How can I or my customers access Spoor?"
  }, {
    "body": "In general, depending on the configuration you choose, there are three ways to get cargo details into Spoor:\n\n1. _Manual Data Entry_\nYou enter your cargo details directly into the database, just as you would fill out an excel sheet.\n\n2. _Excel Upload_\nAfter configuring the upload parameters, you upload your excel packing lists for the respective consignments.\n\n3. _Integration with API_\nAfter configuring the API, Spoor and your system can communicate to fetch your cargo details. Spoor will also respond with GPS or any other available information, should that be required. To make it all work, we need your API Documentation of the system where you manage your cargo details. We can cover most protocols and prefer REST API (JSON/XML).\n\nLearn more about the specific configuration in the answer to the Question: “What are the differences between the three products?”\n[or get in touch](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Data&body=I'm%20interested%20in%20getting%20more%20information%20on%20the%20different%20ways%20to%20connect%20to%20Spoor.)!",
    "identification": "Faq",
    "title": "How can I get my consignment data into Spoor?"
  }, {
    "body": "Our SIM cards have near worldwide coverage (over 197 countries). Make sure you specify the place you intend to use Spoor so that we can ensure you have full coverage in your area of operation.\n\n[Ask us about your area of operation](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Coverage&body=I'm%20interested%20in%20knowing%20if%20your%20services%20cover%20my%20region%20of%20operation.)!\n",
    "identification": "Faq",
    "title": "What is the regional GPRS coverage of your GPS trackers?"
  }, {
    "body": "While it is indeed true that some network providers are shutting down their 2G networks, the rise of M2M and IoT has revived the 2G sector – it has become a reason not to switch those networks off. The way it looks now, it is more likely for 3G to be shut down first.\n\nThe low bandwidth/ low power potential of the older 2G networks have proven to be a valuable, stable, ubiquitous and cost-effective method of moving the small packets of data from M2M and IoT devices around the world. \n\nAdopting to the changes in demand, many providers have been quick to negotiate extensive roaming agreements and make generous offers for the usage of their 2G networks. Nowadays, most network providers are not planning on making any significant changes until well into the next decade – up until the arrival of 5G. \n\nNevertheless, new technology adoption is growing fast and to stay flexible, we also have LPWAN trackers on LTE-M and NB-IoT networks. \n\nAnd finally, Spoor is tracking device agnostic, so we can integrate any device best suitable for your use case.\n\n[Get in touch to learn more](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Networks&body=I'm%20interested%20in%20learning%20more%20about%20your%20LPWAN%20trackers.)!",
    "identification": "Faq",
    "title": "Many GPS trackers run on 2G – aren’t those networks going to be shut down?"
  }, {
    "body": "Some of our customers let the trucker remove the GPS Tracker after the cargo was delivered and then return it to them with the physical POD for him to then get paid. Others have a larger pool of GPS trackers and re-position them on a regular basis.\n\n[Get in touch](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Retrieval&body=I'm%20interested%20in%20learning%20more%20about%20GPS%20tracker%20management.\n) to learn more about GPS tracker management.\n",
    "identification": "Faq",
    "title": "Once the consignment is delivered, how do I get my GPS trackers back?"
  }, {
    "body": "Yes! If you chose the __Standard__ or __Full__ configuration, we could integrate any tracker. You can send us the details on the tracker you want us to integrate for you. Or you can just let us know what you need, and we can find the right tracker for you.\n\nIf you have your own trackers or you are using a Fleet Management system, Spoor can integrate with those too and you can use Spoor to manage your cargo details and as a customer facing information portal.\n\n[Get in touch to learn more](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Tracker&body=I'm%20interested%20in%20learning%20more%20about%20using%20different%20GPS%20trackers.).",
    "identification": "Faq",
    "title": "Can I use any GPS tracker with Spoor?"
  }, {
    "body": "Absolutely! Chose the __Standard__ or the __Full__ configuration, and we will integrate the GPS Signal from your fleet management solution into Spoor for you and your customers to take full advantage of a visible supply chain in real time.\n\n[Get in touch to learn more](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Fleet&body=I'm%20interested%20in%20learning%20more%20about%20integrating%20my%20fleet%20management%20solution.).",
    "identification": "Faq",
    "title": "I have a vehicle fleet which runs on a fleet management software - can I still use Spoor?"
  }, {
    "body": "Yes, that is possible! We have an alert function in Spoor to do exactly that. Depending on the use case you present us and/or the tracker you want to use, we can monitor various aspects of the cargo in transit.\n\nFor example, one of our customers uses Spoor to avoid having to use expensive pilot vehicles for convoys, by remotely monitoring the speed with which the tracked vehicles drive. Or you can get alerts when there is a likely collision, based on the G-Force sensor in our tracker or the sudden loss of speed.\n\nThere are many different possibilities and we are excited to hear your requirements and to build a solution around them – [get in touch](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Monitoring&body=I'm%20interested%20in%20getting%20more%20information%20on%20how%20Spoor%20can%20help%20me%20monitor%20my%20cargo.)!",
    "identification": "Faq",
    "title": "I want to monitor certain aspects of my consignments, like temperature, speed, etc. – is that possible with Spoor?"
  }, {
    "body": "It absolutely can! One of the main motivations for all we do here at PeriPlus is to relieve you of repetitive and boring work. \n\nIn addition to the GPS positions from the tracking devices, we can offer integrations with various third-party track & trace data providers. Creating a chain of real supply chain data.\n\nFor example, we can show you vessel positions and track your cargo down to the container, making sure you are aware when it gets rolled. You can then provide your customers with a login to Spoor (web or App) and they can pull the information they want, instead of you having to push it to them. \n\nYou don't have to send the same messages over and over, and at the same time your customers can get to their information whenever, wherever. \n\n[Get in touch with us to know more](mailto:sales.spoor@periplus.ch?subject=Request%20for%20Information%20-%20Spoor%20FAQ%20Status&body=I'm%20interested%20in%20getting%20more%20information%20on%20how%20Spoor%20can%20help%20me%20to%20send%20status%20updates%20to%20my%20customers.)!",
    "identification": "Faq",
    "title": "I need to send regular status updates to my customers - can Spoor relieve me from this soul-eating task?"
  }, {
    "body": "We are sorry about that, but not to worry. [Send us an E-Mail](mailto:sales.spoor@periplus.ch?subject=Question%20-%20Spoor%20FAQ%20Question&body=I%20have%20a%20Question:), [Book A Call](https://outlook.office365.com/owa/calendar/PeriPlus1@periplus.ch/bookings/) or reach out to us on [LinkedIn](https://www.linkedin.com/company/periplus-ag/) or [Twitter](https://twitter.com/PeriPlusAG)!",
    "identification": "Faq",
    "title": "My question was not answered!"
  }]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      